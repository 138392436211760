import React, { useEffect, useRef, useState } from "react";

export default function Menu({ menu, setMenu }) {
  const menuRef = useRef();

  const close = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    const onScroll = () => {
      const menuAnchor = document.getElementById("STAKT & GOTT");
      const start = menuAnchor;
      const menuItem = menuRef.current;

      if (window.scrollY > start.offsetTop - 50) {
        menuItem?.classList.add("sticky");
      } else {
        menuItem?.classList.remove("sticky");
      }
    };
    window.addEventListener("scroll", onScroll);
  }, [menu.current]);

  useEffect(() => {
    const links = document.querySelectorAll(".menu__inner-link");

    const sections = document.querySelectorAll(".anchor");
    function changeLinkState() {
      let index = sections.length;
      while (--index && window.scrollY + 100 < sections[index].offsetTop) {}
      links.forEach((link) => link?.classList.remove("active"));
      links[index]?.classList.add("active");
    }
    window.addEventListener("scroll", changeLinkState);
    links.forEach((e) => {
      onLinkClick(e);
    });
    function onLinkClick(linkItem) {
      linkItem.addEventListener("click", function () {
        setMenu(false);
        document.body?.classList.remove("active");
      });
    }
  }, []);

  return (
    <>
      <div
        className={"menu " + (menu ? "active" : "")}
        onClick={close}
        ref={menuRef}
      >
        <div className="auto__container">
          <div className="menu__inner">
            <a href="#STJÖRNUMÁLTÍÐ" className="menu__inner-link">
              STJÖRNUMÁLTÍÐ
            </a>
            <a href="#STAKT & GOTT" className="menu__inner-link">
              STAKT & GOTT
            </a>
            <a href="#BARNABOX" className="menu__inner-link">
              BARNABOX
            </a>
            {/* <a href="#KJÚKLINGUR" className="menu__inner-link">
              KJÚKLINGUR
            </a> */}
            <a href="#HEIMSENDING" className="menu__inner-link">
              HEIMSENDING
            </a>
            <a href="#MEÐLÆTI" className="menu__inner-link">
              MEÐLÆTI
            </a>
            <a href="#FJÖLSKYLDUTILBOÐ" className="menu__inner-link">
              FJÖLSKYLDUTILBOÐ
            </a>
            <a href="#METRO CAFÉ" className="menu__inner-link">
              METRO CAFÉ
            </a>
            <a href="#EFTIRRÉTTIR" className="menu__inner-link">
              EFTIRRÉTTIR
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
