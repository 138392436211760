import React from "react";
import Slider from "react-slick";
import { SideModul } from "../Moduls";
import SideItem from "./SideItem";

export default function Side({data}) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 600,
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="side">
      <div className="auto__container">
        <div className="side__inner">
          <h2>MEÐLÆTI</h2>
          <Slider {...settings} className="sideSlider">
            {data?.map((item, index) => {
              return <SideItem {...item} key={index} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
