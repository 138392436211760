export const Qproducts = `*[_type == "products"]{
     title,
     slug,
     info,
     price,
     image{
          asset->{
               url
          }
     },
     category->{
          name,
          slug,
          image{
               asset->{
                    url
               }
          }
     }
}`

export const themeOptions = `*[_type == "themeOptions"][0]{
     slider_products[]->{
          title,
          slug,
          info,
          price,
          image{
               asset->{
                    url
               }
          },
          category->{
               name,
               slug,
               image{
                    asset->{
                         url
                    }
               }
          }
     },
     barnabox{
          meal[]->{
            title,
            image{
               asset->{
                    url
               }
             }
          },
          price,
          side_product{
            side_product_one{
              asset->{
                url
              }
            },
            side_product_one_title,
            side_product_two{
              asset->{
                url
              }
            },
            side_product_two_title
          }
     },
     family_offer{
          products[]{
               image{
                    asset->{
                         url
                    }
               },
               title
          },
          side_products[]{
               image{
                    asset->{
                         url
                    }
               },
          },
          title,
          price
     },
     single_and_good_products[]->{
          title,
          slug,
          info,
          price,
          image{
               asset->{
                    url
               }
          },
          category->{
               name,
               slug,
               image{
                    asset->{
                         url
                    }
               }
          }
     },
     side_dish_products[]->{
          title,
          slug,
          info,
          price,
          image{
               asset->{
                    url
               }
          },
          category->{
               name,
               slug,
               image{
                    asset->{
                         url
                    }
               }
          }
     },
     desserts_products[]->{
          title,
          slug,
          info,
          price,
          image{
               asset->{
                    url
               }
          },
          category->{
               name,
               slug,
               image{
                    asset->{
                         url
                    }
               }
          }
     },
     star_meal{
          meal[]->{
               title,
               slug,
               info,
               price,
               image{
                    asset->{
                         url
                    }
               },
               category->{
                    name,
                    slug,
                    image{
                         asset->{
                              url
                         }
                    }
               }
          },
          b_product_one{
            asset->{
             url 
            }
          },
          b_product_two{
               asset->{
                url 
               }
             },
             c_product{
               asset->{
                url 
               }
             }
        }
}`