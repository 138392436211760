import React from "react";

export default function Barna({ data }) {
  return (
    <div className="barna">
      <div className="barna__image">
        <img
          src={process.env.PUBLIC_URL + "/images/happyMeal.png"}
          alt="happyMeal"
        />
      </div>
      <div className="auto__container">
        <div className="barna__inner">
          <h2>BARNABOX</h2>
          <h5>Veldu máltíð:</h5>
          <div className="barna__inner-row">
            {data?.meal.slice(0, 4)?.map((item, idx) => (
              <div className="barnaItem" key={idx}>
                <div className="barnaItem__image">
                  <img src={item.image?.asset?.url} alt="ostborgari" />
                </div>
                <h6 className="neue">{item.title}</h6>
              </div>
            ))}
          </div>
          <span className="barna__inner-plus">+</span>
          <div className="barna__inner-row">
            <div className="barnaCombo">
              <div className="barnaCombo__image">
                <img
                  src={data?.side_product?.side_product_one?.asset?.url}
                  alt="friFruit"
                />
              </div>
              <h6 className="neue">
                {data?.side_product?.side_product_one_title}
              </h6>
            </div>
            <span className="barna__inner-plus">+</span>
            <div className="barnaCombo">
              <div className="barnaCombo__image">
                <img
                  src={data?.side_product?.side_product_two?.asset?.url}
                  alt="cokeJuice"
                />
              </div>
              <h6 className="neue">
                {data?.side_product?.side_product_two_title}
              </h6>
            </div>
          </div>
          <h4 className="coco">+ Leikfang vikunnar!</h4>
          <div className="fam__inner-price" style={{ color: "white" }}>
            <h3>{data?.price} kr.</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
