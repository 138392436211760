import React from "react";

export default function SideItem(props) {
  return (
    <div className="sideItem">
      <div className="sideItem__image">
        <img src={props.image?.asset?.url} alt={props.id} />
      </div>
      <h6 className="big">{props.title}</h6>
      <h6 className="caps">{props.price} kr</h6>
    </div>
  );
}
