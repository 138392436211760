import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header({ menu, setMenu }) {
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //     setMenu(false);
  //     document.documentElement.classList.remove("active");
  //   }, [location]);
  //   //
  //   useEffect(() => {
  //     fadeOutEffect(document.querySelector(".loader"), 1.2);
  //     fadeOutEffect(document.querySelector("#preloder"), 2, 0.6);
  //   }, []);

  //   const fadeOutEffect = (fadeTarget, delay, animDur = 0.2) => {
  //     document.body.classList.add("active");

  //     const style = `opacity: 1; transition: opacity ${animDur}s ease;`;
  //     fadeTarget.style = style;
  //     setTimeout(() => {
  //       fadeTarget.style = `opacity: 0; transition: opacity ${animDur}s ease;`;
  //       setTimeout(() => {
  //         document.body.classList.remove("active");
  //         fadeTarget.style = `display: none`;
  //       }, animDur * 500);
  //     }, delay * 500);
  //   };

  return (
    <div className="header">
      <div className="auto__container">
        <div className="header__inner">
          <Link to="/" className="header__inner-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="logo" />
          </Link>
          <nav className="nav">
            <div className="nav__inner">
              <Link to="/" className="nav__inner-link">
                forsíða
              </Link>
              <Link
                to="/#HEIMSENDING"
                className="nav__inner-link"
                onClick={(e) => {
                  e.preventDefault();
                  const element = document.getElementById("delivery");
                  if (element) {
                    // 👇 Will scroll smoothly to the top of the next section
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                heimsending
              </Link>
              {/* <Link to="/" className="nav__inner-link">
                starf
              </Link> */}
            </div>
          </nav>
          <div
            className={"burger " + (menu ? "active" : "")}
            id="menuBtn"
            onClick={() => {
              setMenu(!menu);
            }}
          >
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}
