export default function Stefna() {
  return (
    <div className="wrapper">
      <div className="video-container">
        <video
          muted={true}
          autoPlay={true}
          loop={true}
          src={process.env.PUBLIC_URL + "/videos/metro-min-mute.mp4"}
          style={{ width: "100%" }}
        />
      </div>
      <div className="container">
        <div style={{ width: "100%", maxWidth: "1000px", margin: "0 auto" }}>
          <br />
          <br />
          <h2>Jafnlaunastefna</h2>
          <br />
          <p>
            Jafnlaunastefna Metro er ætlað að tryggja jafnrétti og jafna stöðu
            kynja innan fyrirtækisins. Markmiðið er að enginn kynbundinn
            mismunur eigi sér stað og við ákvörðun launa skal gæta þess að
            kynjum sé ekki mismunað. Allir skulu njóta jafnra kjara fyrir jafn
            verðmæt og sambærileg störf sem og hvers konar frekar þóknanna.
            Einnig skulu öll kyn njóta sömu kjara hvað varðar lífeyris-, orlofs-
            og veikindarétt og hver önnur starfskjör eða réttindi sem metin
            verða til fjár.
          </p>
          <br />
          <p>
            Til þess að framfylgja stefnunni munu Metro framkvæma launagreiningu
            árlega þar sem borin eru saman jafnverðmæt og sambærileg störf.
            Jafnlaunamarkmið skulu endurskoðuð út frá niðurstöðum
            launagreiningar. Jafnlaunastefnan felur í sér skuldbindingu um
            stöðugar umbætur og ber forstjóri Metro ábyrgð á innleiðingu,
            viðhaldi og þeim frávikum sem koma fram við rýni á jafnlaunakerfinu.
            Stjórnendur skulu einnig skuldbinda sig til að viðhalda stöðugum
            umbótum, eftirliti og bregðast við óútskýrðum launamun.
          </p>
          <br />
          <p>
            <b>Niðurstöður launagreininga skal kynna starfsfólki ár hvert.</b>
          </p>
          <br />
          <p>
            Forstjóri skal setja fram jafnlaunamarkmið, láta framkvæma innri
            úttekt og rýna jafnlaunakerfið og árangur þess árlega. Forstjóri ber
            ábyrgð á að jafnlaunastefnu sé framfylgt og ber ábyrgð á
            innleiðingu, umbótum og hlítingu á settum viðmiðum, sem og að
            tryggja að stjórnendur þekki stefnuna og uppfylli öll skilyrði
            hennar. Stefnan er í samræmi við lög um jafnan rétt kynjanna nr
            150/2020.
          </p>
          <br />
          <p>
            Jafnlaunastefnan er unnin í samræmi við þau og lög og reglugerðir
            sem fjalla um jafna stöðu og jafnan rétt kynja sem og aðra mismunun
            og er hún hluti af launastefnu fyrirtækisins.
          </p>
          <br />
          <p>
            Jafnlaunastefnu skal kynna fyrir starfsmönnum og hafa aðgengilega á
            vef fyrirtækisins og í handbók starfsmanna á hverjum stað.
            Jafnlaunastefna var samþykkt af stjórn Metro í apríl 2024.
          </p>
          <br />
          <div
            className="grid"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gridGap: "20px",
            }}
          >
            <div>
              <b>MARKMIÐ</b>
            </div>

            <div>
              <b>AÐGERÐ</b>
            </div>

            <div>
              <b>ÁBYRGÐ</b>
            </div>

            <div>
              <b>TÍMARAMMI</b>
            </div>

            <div>
              <p>
                Allir starfsmenn fái greidd sömu laun og njóti sömu kjara fyrir
                sambærileg eða jafnverðmæt störf óháð kyni.{" "}
              </p>
            </div>
            <div>
              <p>
                Ef fram kemur launamismunur sem ekki er hægt að skýra með öðru
                en kynferði verður hann leiðréttur. Vottun verður viðhaldið með
                reglulegri úttekt.{" "}
              </p>
            </div>
            <div>
              <p>Forstjóri.</p>
            </div>
            <div>
              <p>
                Innra eftirlit verður framkvæmt árlega sem og regluleg úttekt
                fagvottunaraðila.
              </p>
            </div>
            <div>
              <p>
                Unnið skal að því að hlutfall kynja í stjórnendastörfum sé sem
                jafnast.
              </p>
            </div>
            <div>
              <p>
                Ef stjórnunarstarf losnar skal gæta þess að bæði kynin hafi
                jafnan aðgang að ráðningu.
              </p>
            </div>
            <div>
              <p>Forstjóri</p>
            </div>

            <div>
              <p>Þegar stjórnendastörf losna.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
