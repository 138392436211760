import React from "react";
import DesertItem from "./DesertItem";
import { DesertModul } from "../Moduls";

export default function Desert({data}) {
  return (
    <div className="desert">
      <div className="auto__container">
        <div className="desert__inner">
          <h2>EFTIRRÉTTIR</h2>
          <div className="desert__inner-row">
            {data?.map((item, index) => {
              return <DesertItem {...item} key={index} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
