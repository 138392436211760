import React from "react";

export default function CafeItem(props) {
  return (
    <div className="cafeItem">
      <div className="cafeItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt={props.id} />
      </div>
      <h5>{props.name}</h5>
      <p className="neue">{props.desc}</p>
    </div>
  );
}
