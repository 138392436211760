import React from "react";
import FamItem from "./FamItem";
import { FamModul } from "../Moduls";

export default function Fam({ data }) {
  return (
    <div className="fam">
      <div className="auto__container">
        <div className="fam__inner">
          <h2>FJÖLSKYLDUTILBOÐ</h2>
          <div className="fam__inner-row">
            {data?.products?.map((item, index) => {
              return <FamItem {...item} key={index} id={index + 1} />;
            })}
          </div>
          <div className="fam__inner-more">
            {data?.side_products?.map((item, idx) => (
              <>
                <div className="fam__inner-more-image" key={idx}>
                  <img src={item?.image?.asset?.url} alt="fries" />
                </div>
                {idx + 1 < data?.side_products.length && <span>+</span>}
              </>
            ))}
          </div>
          <h6>
            <strong>{data?.title}</strong>
          </h6>
          <div className="fam__inner-price">
            <h4>aðeins</h4>
            <h3>{data?.price} kr.</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
