import React from "react";
import CafeItem from "./CafeItem";
import { CafeModul } from "../Moduls";

export default function Cafe() {
  return (
    <div className="cafe">
      <div className="cafe__image">
        <img src={process.env.PUBLIC_URL + "/images/frappo.png"} alt="frappo" />
      </div>
      <div className="auto__container">
        <div className="cafe__inner">
          <h2>
            <span>CAFE</span> METRO
          </h2>
          <div className="cafe__inner-row">
            {CafeModul.map((item, index) => {
              return <CafeItem {...item} key={index} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
