import React from "react";

export default function Hotwings() {
  return (
    <div className="combo">
      <div className="auto__container">
        <div className="combo__inner">
          <div className="combo__inner-content">
            <h2>HOT WINGS</h2>
            <ul>
              <li>3 vængir: 829 kr</li>
              <li>6 vængir: 1429 kr</li>
              <li>9 vængir: 2049 kr</li>
              <li>25 vængir: 5199 kr</li>
            </ul>
          </div>
          <div className="combo__inner-image">
            <img
              src={process.env.PUBLIC_URL + "/images/fotur/hotwings.png"}
              alt="combo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
