import React from "react";
import Slider from "react-slick";
import { StartModul } from "../Moduls";
import StartItem from "./StartItem";

export default function Start({ data }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="start">
      <div className="auto__container">
        <div className="start__inner">
          <h2>STJÖRNUMÁLTÍÐ</h2>
          <div className="start__inner-title">
            <span>A</span>
            <h5>Veldu máltíð:</h5>
          </div>

          <Slider {...settings} className="startSlider">
            {data?.meal?.map((item, index) => {
              return <StartItem {...item} key={index} id={index + 1} />;
            })}
          </Slider>

          <div className="start__inner-row">
            <div className="start__inner-col">
              <div className="start__inner-title">
                <span>B</span>
                <h5>Franskar eða salat:</h5>
              </div>
              <div className="startUniq ">
                <div className="startUniq__image sm">
                  <img src={data?.b_product_one?.asset?.url} alt="chips" />
                </div>
                <h6>
                  <strong>eða</strong>
                </h6>
                <div className="startUniq__image sm">
                  <img src={data?.b_product_two?.asset?.url} alt="salad" />
                </div>
              </div>
            </div>
            <div className="start__inner-col sm">
              <div className="start__inner-title">
                <span>C</span>
                <h5>Veldu drykk:</h5>
              </div>
              <div className="startUniq ">
                <div className="startUniq__image">
                  <img src={data?.c_product?.asset?.url} alt="drinks" />
                </div>
              </div>
            </div>
          </div>
          <h5>
            Getur breytt drykk í sjeik, smoothie eða frappó <span>I50 kr</span>
          </h5>
        </div>
      </div>
    </div>
  );
}
