export const StartModul = [
  {
    id: "1",
    price: "2.499",
    image: "/images/burgers/heimsborgari.png",
    name: "Heimsborgari",
    description:
      "Heimsborgari með osti, súrum gúrkum, lauk, salati og heimsborgarasósu, meðlæti og gos.",
  },
  {
    id: "2",
    price: "2.499",
    image: "/images/burgers/godborgari.png",
    name: "Góðborgari",
    description:
      "Góðborgari með osti, súrum gúrkum, lauk, tómatsósu og sinnepi, meðlæti og gos.",
  },
  {
    id: "3",
    price: "2.499",
    image: "/images/burgers/kjuklingaborgari.png",
    name: "Kjúklingaborgari",
    description:
      "Kjúklingaborgari með kjúkling , káli og laukmajó, meðlæti og gos",
  },
  {
    id: "4",
    price: "2.499",
    image: "/images/sideDish/naggar.png",
    name: "6 Naggar",
    description: "",
  },
  {
    id: "5",
    price: "2.499",
    image: "/images/burgers/2cheese.png",
    name: "2x Ostborgari",
    description:
      "2x ostborgarar með osti, súrum gúrkum, lauk, tómatsósu og sinnepi, meðlæti og gos.",
  },
  {
    id: "6",
    price: "2.699",
    image: "/images/burgers/beikon.png",
    name: "Beikon Deluxe",
    description:
      "Beikon Deluxe máltíð með 2xosti, súrum gúrkum, kríspý lauk, sinnep, tómatsósu,majó og beikoni, meðlæti og gosi.",
  },
  {
    id: "7",
    price: "2.699",
    image: "/images/burgers/newyork.png",
    name: "New York Steakhouse",
    description:
      "New York steakhouse með osti, salati, tómat, rauð lauk, steiktum lauk, beikoni , bull eyes steakhousesósu, majó, meðlæti og gos",
  },
  {
    id: "8",
    price: "2.699",
    image: "/images/burgers/newyork.png",
    name: "New York Steakhouse - Kjúklingur",
    description:
      "New York steakhouse með kjúkling með osti, salati, tómat, rauð lauk, steiktum lauk,  beikoni ,bull eyes steakhousesósu, majó og kríspy kjúklingi, meðlæti og gos.",
  },
  {
    id: "9",
    price: "2.699",
    image: "/images/burgers/stori_tasty_1.png",
    name: "XL Teisty",
    description:
      "XL Teisty með osti x2, lauk, salati, tómötum, beikoni og smoky hamborgarasósu, meðlæti og gos.",
  },
  {
    id: "10",
    price: "2.699",
    image: "/images/burgers/camembert.png",
    name: "Camembert Borgari",
    description:
      "Með osti, beikoni, djúpsteiktum camembert osti, berjasultu og metromajó. Borinn fram með meðlæti og gosi.",
  },
  {
    id: "11",
    price: "1.599",
    image: "/images/burgers/tvofaldur.png",
    name: "Tvöfaldur Ostborgari",
    description:
      "Tvöfaldur ostborgari með osti, súrum gúrkum, lauk, tómatsósu og sinnepi, meðlæti og gos",
  },
  // {
  //   id: "8",
  //   price: "2.499",
  //   image: "/images/burgers/trufflu.png",
  // },
];
export const StaktModul = [
  {
    id: "Camembert Borgari",
    desc: "með osti, beikoni, djúpsteikt camembert ostur, berjasultu og metromajó",
    price: "1.899",
    name: "Camembert Borgari",
    image: "/images/jola.png",
    features: [
      {
        id: "1",
        title: "Sinnep",
        image: "/images/features/sinnep.png",
      },
      {
        id: "2",
        title: "Soja",
        image: "/images/features/soja.png",
      },
      {
        id: "3",
        title: "Glútein",
        image: "/images/features/gluten.png",
      },
      {
        id: "4",
        title: "Mjólk",
        image: "/images/features/mjolk.png",
      },
    ],
    info: [],
  },
  {
    id: "Ostborgari",
    desc: "með osti, súrum gúrkum, lauk, tómatsósu og sinnepi",
    price: "749",
    name: "Ostborgari",
    image: "/images/burgers/burger-11.png",
    features: [
      {
        id: "1",
        title: "Sinnep",
        image: "/images/features/sinnep.png",
      },
      {
        id: "2",
        title: "Soja",
        image: "/images/features/soja.png",
      },
      {
        id: "3",
        title: "Glútein",
        image: "/images/features/gluten.png",
      },
      {
        id: "4",
        title: "Mjólk",
        image: "/images/features/mjolk.png",
      },
    ],
    info: [],
  },
  {
    id: "Tvöfaldur ostborgari",
    name: "Tvöfaldur ostborgari",
    desc: "með osti, súrum gúrkum, lauk, tómatsósu og sinnepi",
    price: "979",
    image: "/images/burgers/burger-22.png",
    features: [
      {
        id: "1",
        title: "Sinnep",
        image: "/images/features/sinnep.png",
      },
      {
        id: "2",
        title: "Soja",
        image: "/images/features/soja.png",
      },
      {
        id: "3",
        title: "Glútein",
        image: "/images/features/gluten.png",
      },
    ],
    info: [],
  },
  {
    id: "Þrefaldur ostborgari",
    name: "Þrefaldur ostborgari",
    desc: "með osti, súrum gúrkum, lauk, tómatsósu og sinnepi",
    price: "1.089",
    image: "/images/burgers/burger-33.png",
    features: [
      {
        id: "1",
        title: "Sinnep",
        image: "/images/features/sinnep.png",
      },
      {
        id: "2",
        title: "Soja",
        image: "/images/features/soja.png",
      },
      {
        id: "3",
        title: "Glútein",
        image: "/images/features/gluten.png",
      },
    ],
    info: [],
  },
  {
    id: "Vegan ostborgari",
    name: "Vegan ostborgari",
    desc: "með osti, súrum gúrkum, lauk, tómatsósu og sinnepi",
    price: "799",
    image: "/images/burgers/burger-44.png",
    features: [
      {
        id: "1",
        title: "Sinnep",
        image: "/images/features/sinnep.png",
      },
      {
        id: "2",
        title: "Soja",
        image: "/images/features/soja.png",
      },
      {
        id: "3",
        title: "Glútein",
        image: "/images/features/gluten.png",
      },
      {
        id: "4",
        title: "Mjólk",
        image: "/images/features/mjolk.png",
      },
    ],
    info: [],
  },
  {
    id: "salsa",
    desc: "með kjúkling, káli, salsasósu og laukmajó",
    price: "949",
    name: "Salsa kjúklingaborgari",
    image: "/images/burgers/salsa.png",
    features: [
      {
        id: "1",
        title: "Sinnep",
        image: "/images/features/sinnep.png",
      },
      {
        id: "2",
        title: "Soja",
        image: "/images/features/soja.png",
      },
      {
        id: "3",
        title: "Glútein",
        image: "/images/features/gluten.png",
      },
      {
        id: "4",
        title: "Mjólk",
        image: "/images/features/mjolk.png",
      },
    ],
    info: [],
  },
  {
    id: "spicysalsa",
    desc: "",
    price: "949",
    name: "Spicy Salsa kjúklingaborgari",
    image: "/images/burgers/spicy.png",
    features: [
      {
        id: "1",
        title: "Sinnep",
        image: "/images/features/sinnep.png",
      },
      {
        id: "2",
        title: "Soja",
        image: "/images/features/soja.png",
      },
      {
        id: "3",
        title: "Glútein",
        image: "/images/features/gluten.png",
      },
      {
        id: "4",
        title: "Mjólk",
        image: "/images/features/mjolk.png",
      },
    ],
    info: [],
  },
  {
    id: "nyjr",
    desc: "með osti, salati, tómat, rauð lauk, steiktum lauk,  beikoni ,bull eyes steakhousesósu, majó",
    price: "949",
    name: "New York Steakhouse Jr.",
    image: "/images/burgers/newyork_jr.png",
    features: [
      {
        id: "1",
        title: "Sinnep",
        image: "/images/features/sinnep.png",
      },
      {
        id: "2",
        title: "Soja",
        image: "/images/features/soja.png",
      },
      {
        id: "3",
        title: "Glútein",
        image: "/images/features/gluten.png",
      },
      {
        id: "4",
        title: "Mjólk",
        image: "/images/features/mjolk.png",
      },
    ],
    info: [],
  },
  {
    id: "keto",
    desc: "",
    price: "1649",
    name: "Ketó borgari",
    image: "/images/burgers/keto.png",
    features: [
      {
        id: "1",
        title: "Sinnep",
        image: "/images/features/sinnep.png",
      },
      {
        id: "2",
        title: "Soja",
        image: "/images/features/soja.png",
      },
      {
        id: "3",
        title: "Glútein",
        image: "/images/features/gluten.png",
      },
      {
        id: "4",
        title: "Mjólk",
        image: "/images/features/mjolk.png",
      },
    ],
    info: [],
  },
];
export const BBQModul = [
  {
    id: "Fata af nöggum",
    name: "Fata af nöggum",
    // desc: "Franskar & gos",
    price: "4.999",
    image: "/images/fotur/naggafatamin.png",
  },
  {
    id: "8 Vængir",
    name: "8 Vængir",
    // desc: "Franskar & gos",
    price: "2.499",
    image: "/images/bbq/wings.png",
  },
  {
    id: "25 vængir",
    name: "25 vængir",
    desc: "Í fötu",
    price: "3.699",
    image: "/images/bbq/box.png",
  },
  {
    id: "4 lundir",
    name: "4 lundir",
    desc: "Franskar og gos",
    price: "2.499",
    image: "/images/bbq/lundir.png",
  },
];
export const ChickenModul = [
  {
    id: "8 Vængir",
    name: "8 Vængir",
    desc: "Franskar & gos",
    price: "2.499",
    image: "/images/bbq/wings.png",
  },
  {
    id: "25 vængir",
    name: "25 vængir",
    desc: "Í fötu",
    price: "3.699",
    image: "/images/bbq/box.png",
  },
];
export const SideModul = [
  {
    id: "Mozarella stangir",
    name: "Mozarella stangir",
    price: "799",
    image: "/images/sideDish/mozarella.png",
  },
  {
    id: "Chili ostatoppar",
    name: "Chili ostatoppar",
    price: "799",
    image: "/images/sideDish/toppar.png",
  },
  {
    id: "4 naggar",
    name: "4 naggar",
    price: "749",
    image: "/images/sideDish/naggar.png",
  },
  {
    id: "Beikon ostafranskar",
    name: "Beikon ostafranskar",
    price: "799",
    image: "/images/sideDish/beikon.png",
  },
  {
    id: "Jalapeno ostafranskar",
    name: "Jalapeno ostafranskar",
    price: "799",
    image: "/images/sideDish/jalapeno.png",
  },
  {
    id: "Krullu-franskar",
    name: "Krullu-franskar",
    price: "849",
    image: "/images/sideDish/franskar.png",
  },
  {
    id: "Camembert bitar",
    name: "Camembert bitar",
    price: "799",
    image: "/images/sideDish/bitar.png",
  },
];
export const FamModul = [
  {
    id: "1",
    name: "8 Ostborgarar",
    image: "/images/burgers/ost.png",
  },
  {
    id: "2",
    name: "4 Heimsborgarar",
    image: "/images/burgers/heims.png",
  },
  {
    id: "3",
    name: (
      <>
        2 Heimsborgarar <br /> 4 Ostborgarar
      </>
    ),
    image: "/images/burgers/groupBurger.png",
  },
];
export const CafeModul = [
  {
    id: "Frappó",
    name: "Frappó",
    desc: "Oreo & skukkulaði, Karamella, súkkulaði",
    image: "/images/caffee/frappo.png",
  },
  {
    id: "Smoothie",
    name: "Smoothie",
    desc: "Jarðaberja & banana, ananas & mangó, berja blast",
    image: "/images/caffee/smoothie.png",
  },
  {
    id: "Kleinuhringir",
    name: "Kleinuhringir",
    desc: "The Simpsons, Oreo, Milka",
    image: "/images/caffee/donuts.png",
  },
  {
    id: "Kaffi",
    name: "Kaffi",
    desc: "Uppáhellt kaffi",
    image: "/images/caffee/caffee.png",
  },
];
export const DesertModul = [
  {
    id: "Ís í bikar",
    name: "Ís í bikar",
    price: "499 kr",
    extra: <span className="extra">Jarðaberja - Súkkulaði - Karamellu</span>,
    image: "/images/ice/dollu.png",
  },
  {
    id: "Flörrí",
    name: "Flörrí",
    price: "799 kr",
    image: "/images/ice/flurry.png",
  },
  {
    id: "Ís í brauðformi",
    name: "Ís í brauðformi",
    price: "469 kr",
    image: "/images/ice/vanileIce.png",
  },
  {
    id: "Sjeik",
    extra: <span className="extra">Jarðaberja - Súkkulaði - Karamellu</span>,
    price: "L : 699 kr. |  S : 799 kr.",
    name: "Sjeik",
    image: "/images/ice/sjeik.png",
  },
];
