import React from "react";

export default function Car() {
  return (
    <div className="car">
      <div className="car__image">
        <img src={process.env.PUBLIC_URL + "/images/car.png"} alt="car" />
      </div>
      <div className="auto__container">
        <div className="car__inner">
          <h2>BÍLALÚGA</h2>
          <h5>Opið alla daga frá klukkan 11 til 22</h5>
        </div>
      </div>
    </div>
  );
}
