import React from "react";

export default function StartItem(props) {
  console.log("🚀 ~ StartItem ~ props:", props);
  return (
    <div className="startItem">
      <div className="startItem__number">{props.id}</div>
      <div className="startItem__name">{props.title}</div>
      <div className="startItem__description">{props.info}</div>
      <div className="startItem__image">
        <img src={props.image.asset?.url} alt={props.id} />
      </div>
      <h6 className="sm">{props.price} KR.</h6>
    </div>
  );
}
