import React from "react";

export default function DesertItem(props) {
  return (
    <div className="desertItem">
      <div className="desertItem__image">
        <img src={props.image?.asset?.url} alt={props.id} />
      </div>
      <div className="desertItem__title">
        <h5>{props.title}</h5>
        <span>{props.info}</span>
      </div>

      <h5>{props.price} KR.</h5>
    </div>
  );
}
