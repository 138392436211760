import React, { useState } from "react";

export default function Delivery() {
  const [selection, setSelection] = useState("");
  const [service, setService] = useState();

  return (
    <div className="delivery" id="delivery">
      <div className="auto__container">
        <div className="delivery__inner">
          <img
            src={process.env.PUBLIC_URL + "/images/heimsending.png"}
            alt="Metro heimsending"
            style={{ height: "100px", marginBottom: "50px" }}
          />
          <h2>HEIMSENDING</h2>
          <h5>Við sendum um allt höfuðborgasvæðið</h5>
          {!service ? (
            <div className="button__row" style={{ cursor: "pointer" }}>
              <a
                onClick={(e) => setService("aha")}
                // href="https://wolt.com/en/isl/reykjavik/restaurant/metro-sudurlandsbraut"
                target="_blank"
                className="button secondary"
              >
                AHA
              </a>
              <a
                onClick={(e) => setService("wolt")}
                // href="https://wolt.com/en/isl/reykjavik/restaurant/metro-smaratorgi"
                target="_blank"
                className="button secondary"
              >
                WOLT
              </a>
            </div>
          ) : service === "wolt" ? (
            // WOLT
            <div className="button__row" style={{ cursor: "pointer" }}>
              <a
                href="https://wolt.com/en/isl/reykjavik/restaurant/metro-sudurlandsbraut"
                target="_blank"
                className="button secondary"
              >
                REYKJAVÍK
              </a>
              <a
                href="https://wolt.com/en/isl/reykjavik/restaurant/metro-smaratorgi"
                target="_blank"
                className="button secondary"
              >
                KÓPAVOGUR
              </a>
            </div>
          ) : (
            // AHA
            <div className="button__row" style={{ cursor: "pointer" }}>
              <a
                href="https://www.aha.is/veitingar/met-sud"
                target="_blank"
                className="button secondary"
              >
                REYKJAVÍK
              </a>
              <a
                href="https://www.aha.is/veitingar/met-kop"
                target="_blank"
                className="button secondary"
              >
                KÓPAVOGUR
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
