export default function Starf() {
  return (
    <div className="wrapper">
      <div className="video-container">
        <video
          muted={true}
          autoPlay={true}
          loop={true}
          src={process.env.PUBLIC_URL + "/videos/metro-min-mute.mp4"}
          style={{ width: "100%" }}
        />
      </div>
      <div className="container">
        <div
          style={{
            width: "100%",
            maxWidth: "1000px",
            margin: "0 auto",
            minHeight: "300px",
          }}
        >
          <br />
          <h2>Sækja um starf</h2>
          <form
            action="https://formspree.io/f/meojqwzg"
            method="POST"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label>
              Nafnið þitt:
              <br />
              <input
                type="text"
                name="name"
                style={{
                  backgroundColor: "rgba(0,0,0,0.05)",
                  padding: "10px",
                  borderRadius: "5px",
                  width: "calc(100% - 20px)",
                  marginBottom: "10px",
                }}
              />
            </label>

            <label>
              Netfangið þitt:
              <br />
              <input
                type="email"
                name="email"
                style={{
                  backgroundColor: "rgba(0,0,0,0.05)",
                  padding: "10px",
                  borderRadius: "5px",
                  width: "calc(100% - 20px)",
                  marginBottom: "10px",
                }}
              />
            </label>

            <label>
              Símanúmerið þitt:
              <br />
              <input
                type="tel"
                name="number"
                style={{
                  backgroundColor: "rgba(0,0,0,0.05)",
                  padding: "10px",
                  borderRadius: "5px",
                  width: "calc(100% - 20px)",
                  marginBottom: "10px",
                }}
              />
            </label>

            <button
              type="submit"
              style={{
                backgroundColor: "black",
                color: "white",
                padding: "20px",
                borderRadius: "5px",
                width: "100%",
                marginTop: "20px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Sækja um
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
