import React from "react";

export default function StaktItem({ active, ...props }) {
  return (
    <div className={"staktItem " + (active ? "active" : " ")}>
      <div className="staktItem__front">
        <div className="staktItem__image">
          <img src={props.image?.asset?.url} alt={props.id} />
        </div>
        <div className="staktItem__about">
          <h6 className="heavy">{props.title}</h6>
          <p className="neue">{props.info}</p>
          <p className="sm">{props.price} kr</p>
        </div>
      </div>
    </div>
  );
}
