import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { StaktModul } from "../Moduls";
import StaktItem from "./StaktItem";

export default function Stakt({ data }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="stakt">
      <div className="auto__container">
        <div className="stakt__inner">
          <h2>STAKT & GOTT</h2>
          <Slider {...settings} className="staktSlider">
            {data?.map((item, index) => {
              return <StaktItem {...item} key={index} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
