import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./Base/Header";
import Home from "./Pages/Home/Home";
import Stefna from "./Pages/Stefna/Stefna";
import Starf from "./Pages/Starf/Starf";

export default function App() {
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);
  useEffect(() => {
    if (menu) {
      document.documentElement.classList.add("active");
    } else {
      document.documentElement.classList.remove("active");
    }
  }, [menu]);

  return (
    <>
      <Header setMenu={setMenu} menu={menu} />
      <main className="main">
        <Routes>
          <Route path="" element={<Home menu={menu} setMenu={setMenu} />} />
          <Route path="/stefna" element={<Stefna />} />
          <Route path="/starf" element={<Starf />} />
        </Routes>
      </main>
      <div
        className="footer"
        style={{
          padding: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
          color: "white",
          flexDirection: "column",
          color: "white",
          position: "relative",
        }}
      >
        <p style={{ width: "100%", textAlign: "center" }}>
          <b>Hafa samband</b>
          <br />
          Hlökkum til að heyra frá þér! Opið 8-16 alla virka daga.
          <p>
            <a href="mailto:bokhald@metroborgari.is" style={{ color: "white" }}>
              bokhald@metroborgari.is
            </a>
          </p>
        </p>
        <br />

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <ul style={{ width: "210px", marginTop: "20px" }}>
            <li>
              <b>Metro</b>
            </li>
            <li>
              <p>Suðurlandsbraut 56</p>
            </li>
            <li>
              <p>108 Reykjavík</p>
            </li>
            <li>
              <p>Sími 5811414</p>
            </li>
          </ul>
          <ul style={{ width: "210px", marginTop: "20px" }}>
            <li>
              <b>Metro</b>
            </li>
            <li>
              <p>Smáratorg 5</p>
            </li>
            <li>
              <p>201 Kópavogur</p>
            </li>
            <li>
              <p>Sími 5517400</p>
            </li>
          </ul>
          <ul style={{ width: "210px", marginTop: "20px" }}>
            <li>
              <b>Metro Skrifstofa</b>
            </li>
            <li>
              <p>Bitruháls 2</p>
            </li>
            <li>
              <p>110 Reykjavík</p>
            </li>
            <li>
              <p>5510088</p>
            </li>
          </ul>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "20px",
            flexWrap: "wrap",
            position: "relative",
          }}
        >
          <a
            href="https://docs.google.com/spreadsheets/d/14Sf6mXRo7-WeUZioPmuxQDM-wMtOYHmeiNWIr8yzkbg/edit?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <button
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                fontWeight: "bold",
                width: "300px",
                margin: "20px",
              }}
            >
              Innihaldslýsingar og næringartöflur
            </button>
          </a>

          <a href="/stefna">
            <button
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                fontWeight: "bold",
                width: "300px",
                margin: "20px",
              }}
            >
              Jafnlaunastefna
            </button>
          </a>

          <a href="/starf">
            <button
              style={{
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                fontWeight: "bold",
                width: "300px",
                margin: "20px",
              }}
            >
              Sækja um starf
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
