import React from "react";

export default function FamItem(props) {
  return (
    <div className="famItem">
      <div className="famItem__number">{props.id}</div>
      <div className="famItem__image">
        <img src={props.image?.asset?.url} alt={props.id} />
      </div>
      <h5>{props.title}</h5>
    </div>
  );
}
